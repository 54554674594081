<template>
    <div id="app">
        <div>
            <div style="height: 2.24rem;"><img src="./assets/logo.png" class="banner"/></div>
            <div class="question">{{datas.yy_text}}</div>
            <div class="tips">视障群体、残障群体、老幼群体等特殊群体辅助阅读服务</div>
            <div class="title">——语音播报小助手——</div>
            <div class="readImgView">
                <img @click="playVoice" :src="isRead?require('./assets/666.gif'):require('./assets/555.png')" class="readImg"/>
            </div>
            <img src="./assets/footer.png" style="width: 100%;height: auto;display: block" alt="">
        </div>
    </div>
</template>

<script>
    import TTSRecorder from "./assets/js/TTSrecorder";

    export default {
        name: 'App',
        data() {
            return {
                audioSrc: '',
                id: '20',
                catid: '7',
                modelid: '1',
                datas: {},
                isRead: false,
                ttsRecorder:null
            }
        },
        mounted() {
            // this.getDetailData()
            this.getPageParame()
        },
        methods: {
            playVoice(){
                if(this.isRead){
                    this.ttsRecorder.stop()
                    return
                }
                this.initTTSrecorder(this.datas.yy_text)
            },
            getPageParame(){
                this.id=this.getUrlParms('id')
                this.catid=this.getUrlParms('catid')
                this.modelid=this.getUrlParms('modelid')
                if(this.id&&this.catid&&this.modelid){
                    this.getDetailData()
                }
            },
            getDetailData() {
                const {id, catid, modelid} = this
                const _this = this
                $.ajax({
                    url: 'https://yyfsj.jnbywh.cn/index.php?m=content&c=index&a=showjson',
                    type: 'post',
                    dataType: 'json',
                    data: {
                        id: id,
                        catid: catid,
                        modelid: modelid
                    },
                    success: function (res) {
                        _this.$set(_this, 'datas', res.data)
                    }
                })
            },
            getUrlParms(name) {
                var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
                var r = window.location.search.substr(1).match(reg);
                if (r != null)
                    return unescape(r[2]);
                return null;
            },
            initTTSrecorder(text) {
                const _this=this
                const ttsRecorder = new TTSRecorder();
                this.$set(this,'ttsRecorder',ttsRecorder)
                ttsRecorder.setParams({
                    text: text
                })
                ttsRecorder.start();
                ttsRecorder.onWillStatusChange = function (oldStatus, status) {
                    // 可以在这里进行页面中一些交互逻辑处理：按钮交互等
                    if (status === 'play') {
                        console.log('play')
                        _this.isRead=true
                    }
                    if (status === 'endPlay') {
                        console.log('endPlay')
                        _this.isRead=false
                    }
                };
            }
        }
    }
</script>

<style>
    html {
        background: linear-gradient(269deg, #3698fc 0%, #0064e8 100%);

    }

    html, body {
        margin: 0;
    }

    * {
        box-sizing: border-box;
    }

    .banner {
        width: 7.5rem;
        height: 2.24rem;
        margin: 0 auto 0.39rem;
    }

    .tips {
        font-size: 0.36rem;
        line-height: 0.5rem;
        color: #fff;
        font-weight: bold;
        padding: 0 0.37rem;
        margin: 0.28rem 0 0.62rem;
    }

    .title {
        font-size: 0.5rem;
        line-height: 0.75rem;
        color: #fff;
        margin-bottom: 0.27rem;
        text-align: center;
    }

    .readImgView {
        background-color: #fff;
        height: 3.27rem;
        background: url(./assets/whiteBorder.png) no-repeat;
        background-size: 7.5rem 3.27rem;
        margin: 0 auto 0.6rem;
        padding-left: 0.2rem;
    }

    .readImg {
        width: 6.86rem;
    }

    .question {
        width: 7.5rem;
        height: 2.6rem;
        background: url(./assets/redBorder.png) no-repeat;
        background-size: 7.5rem 2.64rem;
        padding: 0.48rem 0.41rem 0.36rem 0.45rem;
        font-size: 0.4rem;
        line-height: 0.75rem;
        color: #fff;
        font-weight: bold;
        /*display:-webkit-box;!**对象作为伸缩盒子模型展示**!*/
        /*-webkit-box-orient:vertical;!**设置或检索伸缩盒子对象的子元素的排列方式**!*/
        /*-webkit-line-clamp:2;!**显示的行数**!*/
        /*overflow:hidden;!**隐藏超出的内容**!*/

    }
</style>
